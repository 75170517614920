import { getAltForNft } from "../../helpers";
import { Address } from "viem";
import { WalletType } from "../../../contracts/types";
import { bulkTransferContract } from "../../../contracts";
import getMaximumBidFromReservoir from "../../helpers/getMaximumBidFromReservoir";
import unlockdWalletModule from "../../UnlockdWalletModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  SupportedChainIds,
  externalWalletModule,
  verisModule,
} from "../../../clients/verisModule";
import { MarketItemType } from "contracts/MarketContract";
import collectionsModule from "logic/CollectionsModule";
import currenciesModule from "logic/CurrenciesModule";
import { INft, OwnerType } from "./INft";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { IErc721Collection } from "../collection/ICollection";
import { calculateAvailableToBorrowByNft } from "logic/helpers/math";
import createAssetId from "logic/helpers/createAssetId";
import { getNftId } from "logic/helpers/nfts/nftId";
import nftMetadataModule from "./NftMetadataModule";
import UnlockdService from "data/UnlockdService";
import NftPrices from "./NftPrices";
import { NftModel } from "data/store/models";
import { SortingOrder } from "alchemy-sdk";
import alchemyService from "data/AlchemyService";
import contractsService from "data/ContractsService";
import NftsModule from "logic/NftsModule";
import MarketItemData from "./MarketItemData";
import { TheGraphService } from "data/TheGraphService";

export type NftOptions = Parameters<typeof NftModel.save>[1];

export abstract class Nft implements INft {
  static model = NftModel;
  readonly _collection: IErc721Collection;
  readonly tokenId: string;
  readonly isDeposited: boolean;
  readonly chainId: SupportedChainIds;
  // readonly marketItemData?: MarketItemData;

  static async bulkTransfer(
    nfts: Nft[],
    to: Address,
    options?: OptionsWriteMethod,
    walletType: WalletType = WalletType.BASIC
  ) {
    if (nfts.length === 0) {
      throw new Error("at least one nfts must be passed to transfer");
    }

    // parse the nfts to adapt them to the contract method type
    const nftsParsed = nfts.map(({ collection, tokenId }) => ({
      contractAddress: collection,
      tokenId: BigInt(tokenId),
    }));

    const output = await bulkTransferContract.batchTransferFrom(
      nftsParsed,
      to,
      options,
    );

    nfts.forEach((nft) => {
      Nft.model.save(nft.nftId, { owner: to });

      NftsModule.removeNftFromCache(
        walletType === WalletType.BASIC
          ? externalWalletModule.address!
          : unlockdWalletModule.unlockdAddress!,
        nft.collection,
        nft.tokenId,
        nft.chainId
      );

      if (
        to === externalWalletModule.address ||
        to === unlockdWalletModule.unlockdAddress
      ) {
        NftsModule.addNftToCache(to, {
          collection: nft.collection,
          tokenId: nft.tokenId,
          chainId: nft.chainId,
          isDeposited: nft.isDeposited,
          owner: to,
        });
      }
    });

    return output;
  }

  constructor(
    collection: Address,
    tokenId: string,
    chainId: SupportedChainIds,
    isDeposited: boolean,
    options?: NftOptions
  ) {
    this._collection = collectionsModule.getCollectionByAddress(collection, chainId);
    this.tokenId = tokenId;
    this.isDeposited = isDeposited;
    this.chainId = chainId

    if (options) {
      Nft.model.save(getNftId(collection, tokenId, this.chainId), { ...options });
    }
  }

  get nftId() {
    return getNftId(this.collection, this.tokenId, this.chainId);
  }

  get assetId() {
    return createAssetId(this.collection, this.tokenId);
  }

  get name() {
    return this._collection.name;
  }

  get collection() {
    return this._collection.address;
  }

  get alt() {
    return getAltForNft(this.name, this.tokenId);
  }

  // METADATA
  get image() {
    return nftMetadataModule.getNftImage(this.nftId);
  }

  get attributes() {
    return nftMetadataModule.getNftAttributes(this.nftId);
  }

  get title(): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const title = await nftMetadataModule.getNftTitle(this.nftId);

      title ? resolve(title) : resolve(this.nameToShow);
    });
  }

  // PRICES
  get prices() {
    return new Promise<NftPrices>(async (resolve, reject) => {
      const { prices } = Nft.model.findByIdLeanNullSafe(this.nftId);

      if (prices) {
        resolve(prices);
      }

      try {
        const { liquidationThreshold, ltv, valuation } =
          await UnlockdService.get(this.chainId).getNftPrice(this);

        Nft.model.save(this.nftId, {
          prices: { valuation, ltv, liquidationThreshold },
        });

        resolve({ valuation, ltv, liquidationThreshold });
      } catch (err) {
        reject(err);
      }
    });
  }

  get valuation() {
    return new Promise<bigint>(async (resolve, reject) => {
      try {
        const prices = await this.prices;

        resolve(prices.valuation);
      } catch (err) {
        reject(err);
      }
    });
  }

  get ltv() {
    return this.prices.then(({ ltv }) => ltv);
  }

  get liquidationThreshold() {
    return this.prices.then(({ liquidationThreshold }) => liquidationThreshold);
  }

  get availableToBorrow() {
    return new Promise<bigint>(async (resolve) =>
      resolve(
        calculateAvailableToBorrowByNft(await this.valuation, await this.ltv)
      )
    );
  }

  get notValuated() {
    return new Promise<boolean>(async (resolve) =>
      resolve((await this.valuation) === BigInt(0))
    );
  }

  // ownership
  get owner(): Promise<Address> {
    return new Promise<Address>(async (resolve) => {
      const { owner } = Nft.model.findByIdLeanNullSafe(this.nftId);

      if (owner) {
        resolve(owner as Address);
      } else {
        const ownerFromChain = await contractsService.getNftOwner(
          this.collection,
          this.tokenId,
          this._collection.contract,
          false,
          this.chainId
        );

        Nft.model.save(this.nftId, { owner: ownerFromChain });

        resolve(ownerFromChain);
      }
    });
  }

  get ownerType(): Promise<OwnerType> {
    return new Promise<OwnerType>(async (resolve) => {
      const owner = await this.owner;

      resolve(
        externalWalletModule.address &&
          equalIgnoreCase(owner, externalWalletModule.address)
          ? OwnerType.EXTERNAL
          : unlockdWalletModule.unlockdAddress &&
            equalIgnoreCase(owner, unlockdWalletModule.unlockdAddress)
            ? OwnerType.UNLOCKD
            : OwnerType.OTHER
      );
    });
  }

  // currency
  get currency() {
    return this._collection.currenciesSupported[0];
  }

  // market item
  get marketItemData(): Promise<MarketItemData | null> {
    return new Promise<MarketItemData | null>(async (resolve) => {
      const { marketItemData } = Nft.model.findByIdLeanNullSafe(this.nftId);

      if (marketItemData) {
        resolve(marketItemData);
      } else {
        const marketItemData = await TheGraphService.get(this.chainId).getMarketItemFromNft(
          this.assetId
        );

        if (marketItemData) {
          Nft.model.save(this.nftId, { marketItemData });
        }

        resolve(marketItemData);
      }
    });
  }

  // listing getters
  get isListed(): Promise<boolean> {
    return new Promise(async (resolve) => {
      resolve(!!(await this.marketItemData));
    });
  }

  get isCancelListAvailable(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const marketItemData = await this.marketItemData;

      if (await !this.isListed) {
        resolve(false);
      } else if (
        !marketItemData ||
        !marketItemData.bids ||
        !marketItemData.type ||
        !marketItemData.biddingEnd
      ) {
        resolve(false);
      } else if (
        marketItemData.type === MarketItemType.TYPE_LIQUIDATION_AUCTION
      ) {
        resolve(false);
      } else if (marketItemData.type === MarketItemType.TYPE_FIXED_PRICE) {
        resolve(true);
      } else if (marketItemData.biddingEnd > Date.now()) {
        resolve(true);
      } else if (marketItemData.bids.length === 0) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  get nameToShow(): string {
    return `${this.name} #${this.tokenId}`;
  }

  get lastSalePrice() {
    return new Promise<bigint | undefined>(async (resolve) => {
      const { lastSalePrice: data } = Nft.model.findByIdLeanNullSafe(
        this.nftId
      );

      if (data) return data;

      if (verisModule.isMainnetConnected) {
        const { nftSales } = await alchemyService.getNftSales(
          this.collection,
          this.tokenId,
          this.chainId,
          SortingOrder.DESCENDING
        );

        const salePrice =
          nftSales.length > 0 && nftSales[0].sellerFee.amount
            ? BigInt(nftSales[0].sellerFee.amount)
            : undefined;

        if (salePrice) {
          Nft.model.save(this.nftId, { lastSalePrice: salePrice });
        }

        resolve(salePrice);
      } else {
        return BigInt(10e7);
      }
    });
  }

  // METHODS
  async getValuationInUsd(): Promise<bigint> {
    return this.currency.parseToDollar(await this.valuation);
  }

  async getAvToBorrowInUsd(): Promise<bigint> {
    return this.currency.parseToDollar(await this.availableToBorrow);
  }

  get reservoirBid(): Promise<{
    amount: bigint;
    netAmount: bigint;
    id: string;
  } | null> {
    return new Promise<{
      amount: bigint;
      netAmount: bigint;
      id: string;
    } | null>(async (resolve, reject) => {
      try {
        const bidInWrappedCurrency = await getMaximumBidFromReservoir({ chainId: this.chainId, collection: this.collection, tokenId: this.tokenId });

        if (!bidInWrappedCurrency || this.currency.isWrappedNative) {
          resolve(bidInWrappedCurrency);
        } else {
          const nativeCurrency = currenciesModule.getNativeCurrency(this.chainId);

          const amountInUsd = await nativeCurrency.parseToDollar(bidInWrappedCurrency.amount);
          const netAmountInUsd = await nativeCurrency.parseToDollar(
            bidInWrappedCurrency.netAmount
          );

          const amountInNftCurrency = await this.currency.parseFromDollar(
            amountInUsd
          );
          const netAmountInNftCurrency = await this.currency.parseFromDollar(
            netAmountInUsd
          );

          resolve({
            ...bidInWrappedCurrency,
            amount: amountInNftCurrency,
            netAmount: netAmountInNftCurrency,
            id: bidInWrappedCurrency.id,
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  abstract transfer(
    from: Address,
    to: Address,
    options?: OptionsWriteMethod,
    walletType?: WalletType
  ): Promise<Output<void>>;
}
